import React from 'react';

const Service = ({ service }) => {
  return (
    <div className="container mx-auto p-4 w-1/2 min-w-96 h-40">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-2">{service.name}</h1>
          {service.description && (
            <p className="text-gray-600 mb-4">{service.description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
