import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-950 p-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="text-white text-center md:text-left mb-4 md:mb-0">
          &copy; {new Date().getFullYear()} 恆立電子. All Rights Reserved.
        </div>
        <div className="text-white text-center md:text-left md:mb-0 space-y-2">
            <h1 className="text-xl font-bold">聯絡方式</h1>
            <p>手機電話: (+886) 920805356</p>
            <p>Line ID: 0920805356</p>
        </div>
        <div className="text-white text-center md:text-left md:mb-0 space-y-2">
            <p>Email: <a href="mailto:ipad99927@gmail.com" className="text-white hover:text-gray-300">ipad99927@gmail.com</a></p>
            <p>地址: 台中市南屯區大進街98-4號</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
