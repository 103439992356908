import { useState, useEffect } from "react";
import axiosInstance from "../Axios/axios";
import Service from "../components/Service";

function ServicePage() {
    const [services, setServices] = useState([]);

    useEffect(() => {
        axiosInstance
            .get("/service")
            .then((res) => {
                setServices(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    return (
        <div className="flex flex-wrap my-12">
            <h1 className="text-6xl font-bold text-center w-full mb-12">修理案例</h1>
            {services.map((service) => (
                <Service key={service._id} service={service} />
            ))}
        </div>
    );
}

export default ServicePage;
