import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import MainPage from "./pages/MainPage";
import ProductPage from "./pages/ProductPage";
import ServicePage from "./pages/ServicePage";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="flex flex-col min-h-screen">
    <BrowserRouter>
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/service" element={<ServicePage />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
