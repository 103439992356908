import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ScrollTrigger from 'react-scroll-trigger';

const MainPage = () => {
    const [serviceInView, setServiceInView] = useState(false);
    const [brandsInView, setBrandsInView] = useState(false);

    const brandList = [
        'abb.jpg', 'baumuller.png', 'bosch.png', 'delta.png',
        'kollmorgen.png', 'kuka.jpg', 'mitsubishi.png',
        'schneider.jpg', 'siemens.png', 'staubli.png', 'yaskawa.png'
    ];

    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="text-center relative">
                <img src="/hero.jpg" alt="Main" className="w-full h-long object-cover" />
                <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50">
                    <h1 className="text-6xl font-bold mb-4 text-white">恆立電子</h1>
                    <p className="text-xl text-white mb-6">
                        以邏輯的方法來提高電路板的修復率
                    </p>
                </div>
            </div>
            <main className="flex-grow bg-gray-100 p-6">
                <h1 className="text-6xl font-bold my-12 text-center">服務項目</h1>
                <ScrollTrigger onEnter={() => setServiceInView(true)} onExit={() => setServiceInView(false)}>
                    <div className="container mx-auto text-center">
                        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
                            {[
                                {
                                    title: "工業電路板維修",
                                    description: "協助維修變頻器, 伺服驅動器, PLC等工業電路板"
                                },
                                {
                                    title: "逆向工程與電路圖還原",
                                    description: "提供逆向工程還原電路板為電路圖讓企業可自行修理"
                                },
                                {
                                    title: "電路板維修訓練",
                                    description: "訓練讓您的企業及您能夠自行維修"
                                },
                                {
                                    title: "進口各品牌伺服驅動馬達",
                                    description: "提供各品牌伺服驅動馬達"
                                }
                            ].map((item, index) => (
                                <motion.div
                                    key={index}
                                    initial="hidden"
                                    animate={serviceInView ? 'visible' : 'hidden'}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                    variants={fadeInUp}
                                    className="bg-white shadow-md rounded-lg p-4 w-full md:w-1/4 h-36"
                                >
                                    <h2 className="text-2xl font-semibold mb-2 h-12">{item.title}</h2>
                                    <p className="text-gray-600 h-12">{item.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </ScrollTrigger>
                <h1 className="text-6xl font-bold my-24 text-center">維修市面上各種電路板品牌</h1>
                <ScrollTrigger onEnter={() => setBrandsInView(true)} onExit={() => setBrandsInView(false)}>
                    <div className="container mx-auto text-center mb-12">
                        <div className="flex flex-wrap justify-center items-center space-x-4">
                            {brandList.map((brand, index) => (
                                <motion.img
                                    key={index}
                                    src={`/${brand}`}
                                    alt={brand}
                                    className="w-48 h-24 object-contain"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={brandsInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.5 }}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                />
                            ))}
                        </div>
                    </div>
                </ScrollTrigger>
            </main>
        </div>
    );
};

export default MainPage;
