import React from 'react';

const Product = ({ product }) => {
  return (
    <div className="container mx-auto p-4 w-1/4 min-w-96 h-long">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src={product.photoUrl}
          alt={product.title}
          className="w-full h-72 object-cover"
        />
        <div className="p-6 h-52">
          <h1 className="text-2xl font-bold mb-2">{product.title}</h1>
          <p className="text-gray-600 mb-4">{product.description}</p>
          <div className="text-gray-800">
            {product.manufacturer && (
              <div className="mb-2">
                <span className="font-semibold">製造商:</span>
                <span className="ml-2">{product.manufacturer}</span>
              </div>
            )}
            {product.status && (
              <div className="mb-2">
                <span className="font-semibold">目前:</span>
                <span className="ml-2">{product.status}</span>
              </div>
            )}
            {product.price && (
              <div className="mb-2">
                <span className="font-semibold">價格:</span>
                <span className="ml-2">${product.price}</span>
              </div>
            )}
            {product.condition && (
              <div className="mb-2">
                <span className="font-semibold">狀態:</span>
                <span className="ml-2">{product.condition}</span>
              </div>
            )}
            {product.inventory && (
              <div className="mb-2">
                <span className="font-semibold">庫存:</span>
                <span className="ml-2">{product.inventory}</span>
              </div>
            )}
            {product.type && (
              <div>
                <span className="font-semibold">類別:</span>
                <span className="ml-2">{product.type}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
