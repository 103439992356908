import { useState, useEffect } from "react";
import axiosInstance from "../Axios/axios";
import Product from "../components/Product";


function ProductPage() {

    const [product, setProduct] = useState(null);

    useEffect(() => {
        axiosInstance
            .get("/product")
            .then((res) => {
                setProduct(res.data);
            })
            .catch((err) => {
                console.error(err)
            })
    }, []);

    return (
        <div className="flex flex-wrap my-12">
            {product && product.map((product) => (
                <Product key={product._id} product={product} />
            ))}
        </div>
    );
}

export default ProductPage;