import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-950 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-bold">
          <a href="/">恆立電子</a>
        </div>
        <div className="hidden md:flex space-x-4">

          <a href="/service" className="text-white hover:text-gray-300">修理案例</a>
          <a href="/product" className="text-white hover:text-gray-300">產品資訊</a>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-gray-900 mt-2">
          <a href="/service" className="block px-4 py-2 text-white hover:bg-gray-800">修理案例</a>
          <a href="/product" className="block px-4 py-2 text-white hover:bg-gray-800">產品資訊</a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
